.footer {
	padding: 50px 0 $global-small-margin;
	border-top: 1px solid $grey-inverse;

	&-bottom {
		padding: $global-gutter 0 0;
		font-family: $font-recoleta;
		font-size: $font-small;
	}

	.uk {

		&-logo {

			img {
				width: 220px;
			}
		}
	}

	.watermark {

		a {
			align-items: center;
			color: $white;
			display: inline-flex;
			font-weight: 700;

			@include hover {
				color: #e11e2f;
				text-decoration: none;
			}

			img {
				height: 1rem;
				margin-left: 5px;
				position: relative;
				top: -2px;
				width: auto;
			}
		}
	}

	.legal {

		ul {
			margin: 0;
			padding: 0;

			li {
				list-style-type: none;
				margin-left: $gutter-small;

				&:first-child {
					margin-left: 0;
				}

				a {
					position: relative;

					&::after {
						background-color: $white;
						bottom: 0;
						content: '';
						height: 1px;
						left: 0;
						opacity: 0;
						position: absolute;
						transition: $transition;
						width: 100%;
					}

					@include hover {

						&::after {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@media (min-width: $breakpoint-small) {
		padding: $global-large-margin 0 $global-margin;
	}
}