@import 'base/fonts';
@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'base/reset';
@import 'base/typography';

@import 'layout/header';
@import 'layout/footer';

@import 'components/banner';
@import 'components/content';
@import 'components/responsive';
@import 'components/videos';
@import 'components/social';
@import 'components/newsletter';
// +++ Generated components will be added above this line

@import 'components/nb/form';

@import 'pages/home';

@import 'utils/browser';
@import 'utils/print';

// Cookie Consent
.nb-cookie-consent-modal {
    &-dialog,
    .uk-modal-header,
    .uk-modal-footer {
        background-color: $black;
        border-color: $sea;
    }
}
