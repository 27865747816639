.content {

	p {
		>a:not(.uk-accordion-title):not(.uk-button) {
			position: relative;
			display: inline;
			text-decoration: none;
			transition: $transition;
			z-index: 0;
			@include word-break;
			box-shadow: inset 0 -2px $global-color;

			@include hover {
				text-decoration: none;
				box-shadow: inset 0 -30px $global-color;
				color: $white;
			}
		}
	}

	blockquote {

		hr {
			display: none;

			+ p {
				font-size: $font-size;
				font-style: normal;
				font-weight: $normal;
			}
		}
	}

	figure {
		caption-side: bottom;
		display: table;
		margin-top: 0;
		outline: none;

		a {
			display: inline-block;
			width: auto;
		}

		img {
			display: block;
			height: auto;

			@media (max-width: $breakpoint-mobile) {
				width: 100%;
			}
		}
	}

	figcaption {
		@include image-caption;
		display: table-caption;
	}

	.align_right {
		float: right;
		margin-bottom: $global-medium-gutter;
		margin-left: $global-medium-gutter;
	}

	.align_left {
		float: left;
		margin-bottom: $global-medium-gutter;
		margin-right: $global-medium-gutter;
	}

	.align_center {
		clear: both;
		margin: $global-gutter auto;
		text-align: center;
	}

	@media (max-width: $breakpoint-mobile) {

		.align_right,
		.align_left,
		.align_center {
			clear: both;
			float: none;
			margin: $global-small-gutter 0;
			width: 100%;
		}
	}
}
