.responsive {
	height: 0;
	margin-bottom: $gutter;
	overflow: hidden;
	position: relative;
	padding-bottom: 56.25%;

	&-wide {
		padding-bottom: 45%;

		iframe {
			max-height: calc(100vh - 100px);
		}
	}

	iframe,
	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.card-video {
		min-height: 0;
	}

	@media (min-width: $breakpoint-large) {

		&-wide {
			width: calc(100% + 80px);
			left: -40px;
		}
	}
}
