@font-face {
	font-family: 'Recoleta';
	src: url('../fonts/Recoleta-Medium/font.woff2') format('woff2'),
		url('../fonts/Recoleta-Medium/font.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Recoleta';
	src: url('../fonts/Recoleta-Bold/font.woff2') format('woff2'),
		url('../fonts/Recoleta-Bold/font.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TT Norms';
	src: url('../fonts/TTNormsPro-Regular/font.woff2') format('woff2'),
		url('../fonts/TTNormsPro-Regular/font.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TT Norms';
	src: url('../fonts/TTNormsPro-Italic/font.woff2') format('woff2'),
		url('../fonts/TTNormsPro-Italic/font.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'TT Norms';
	src: url('../fonts/TTNormsPro-Bold/font.woff2') format('woff2'),
		url('../fonts/TTNormsPro-Bold/font.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TT Norms';
	src: url('../fonts/TTNormsPro-BoldItalic/font.woff2') format('woff2'),
		url('../fonts/TTNormsPro-BoldItalic/font.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'TT Norms';
	src: url('../fonts/TTNormsPro-Light/font.woff2') format('woff2'),
		url('../fonts/TTNormsPro-Light/font.woff') format('woff');
	font-weight: 300;
	font-display: swap;
}
