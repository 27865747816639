.banner {
	@include gutter-full;

	&-overlay {
		content: '';
		position: absolute;
		inset: 0;
		width: 100%;
		background-image: linear-gradient(to top, $black, rgba($black, .5), rgba($black, .3), rgba($black, .1), transparent);
		z-index: 2;
		pointer-events: none;
	}

	.uk-position-cover {
		width: 100%;
	}

	&-text {
		position: absolute;
		left: 0;
		z-index: 3;
		bottom: $global-small-margin;
		padding: 0 $global-margin;
		pointer-events: none;
	}

	.nb-iframe-button {
		cursor: pointer;

		&::before,
		&::after {
			position: absolute;
			content: '';
			pointer-events: none;
			left: 50%;
			top: 45%;
			z-index: 2;
			transition: $transition;
			transform-origin: left;
			opacity: .75;
		}

		&::before {
			width: 45px;
			height: 45px;
			background-color: $global-color;
			border-radius: 50%;
			transform: translate(-50%, -50%);
		}

		&::after {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 0 10px 20px;
			border-color: transparent transparent transparent $white;
			transform: translate(-40%, -50%);
		}
	}

	@include hover {
		.nb-iframe-button {

			&::before,
			&::after {
				opacity: 1;
			}
		}
	}

	.responsive {
		margin-bottom: 0;
	}

	@media (min-width: $breakpoint-small) {
		&-text {
			padding: 0 $global-medium-margin;
		}

		.nb-iframe-button {
			&::before {
				width: 70px;
				height: 70px;
			}

			&::after {
				border-width: 16px 0 16px 32px;
			}
		}
	}

	@media (min-width: $breakpoint-medium) {
		.nb-iframe-button {

			&::before,
			&::after {
				top: 40%;
			}

			&::before {
				width: 100px;
				height: 100px;
			}

			&::after {
				border-width: 21px 0 21px 42px;
			}
		}
	}

	@media (min-width: $breakpoint-large) {
		&-text {
			bottom: $global-large-margin;
		}

		.nb-iframe-button {

			&::before,
			&::after {
				top: 45%;
			}
		}
	}
}