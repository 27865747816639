.header {
	background-color: $black;
	padding: $global-small-margin 0;

	.uk {

		&-logo {
			font-family: $font-recoleta;
			font-weight: $bold;
		}

		&-navbar-container {
			padding-left: $global-small-gutter;
			padding-right: $global-small-gutter;

			@media (min-width: $breakpoint-large) {
				padding-left: $global-medium-margin;
				padding-right: $global-medium-margin;
			}
		}
	}

	@media (max-width: $breakpoint-mobile) {
		.uk-logo {
			font-size: 32px;
		}
	}
}