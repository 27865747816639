/*body,
html {
	overflow-x: hidden;
}*/

* {
	box-sizing: border-box;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

.icon {
	height: 20px;
}