.nb-form {

	+ .nb-form {
		margin-top: $global-gutter;
	}

	&-content {
		position: relative;

		ul {
			margin: 0 0 $margin-small;
			padding: 0;

			li {
				display: inline-block;
				list-style: none;
				padding: $margin-small 0;
				width: 100%;
			}
		}

		.content {

			p {
				font-family: $font-default;
				font-size: $font-small;
			}
		}

		.InputfieldRadiosFloated {

			li {
				padding-right: $gutter;
				width: auto;
			}
		}

		[aria-invalid='true'] {
			border-color: $pink;
		}
	}

	&-error {
		font-size: $font-small;
		font-weight: $bold;
		margin-bottom: 5px;
	}
}
