//
// Breakpoints
//
$breakpoint-small: 640px;
$breakpoint-tablet: 768px; // Custom
$breakpoint-medium: 960px;
$breakpoint-large: 1200px;
$breakpoint-xmedium: 1366px; // Custom
$breakpoint-xlarge: 1600px;
$breakpoint-xsmall-max: ($breakpoint-small - 1);
$breakpoint-small-max: ($breakpoint-medium - 1);
$breakpoint-tablet-min: ($breakpoint-tablet - 1); // Custom
$breakpoint-medium-max: ($breakpoint-large - 1);
$breakpoint-xmedium-min: ($breakpoint-xmedium - 1); // Custom
$breakpoint-large-max: ($breakpoint-xlarge - 1);
$breakpoint-mobile: 479px;


//
// Global Variables
//

// Typography
$global-font-family: $font-default;
$global-font-size: 16px;
$global-line-height: $line-height-large;
$global-2xlarge-font-size: 74px;
$global-xlarge-font-size: 44px;
$global-large-font-size: 34px;
$global-medium-font-size: 26px;
$global-small-font-size: $font-small;

// Colors
$global-color: $orange;
$global-emphasis-color: $global-color;
$global-muted-color: brand-colour($sea, 20%);
$global-link-color: $global-color;
$global-link-hover-color: $global-color;
$global-inverse-color: $white;

// Backgrounds
$global-background: $white;
$global-muted-background: brand-colour($sea, 90%);
$background-primary-background: $primary;

// Borders
$global-border-width: $border-width;
$global-border: brand-colour($sea, 90%);

// Spacings
$global-margin: $margin;
$global-small-margin: $margin-small;
$global-medium-margin: $gutter-medium;
$global-large-margin: $gutter-large;
$global-xlarge-margin: $gutter-xlarge;
$global-gutter: $gutter;
$global-small-gutter: $gutter-small;
$global-medium-gutter: $gutter-medium;
$global-large-gutter: $gutter-large;

// Controls
$global-control-height: 48px;
$global-control-small-height: 32px;
$global-control-large-height: 52px;
$global-control-xlarge-height: ($global-control-small-height * 2); // Custom


//
// Base
//
$base-body-font-weight: $normal;
$base-link-hover-text-decoration: none;
$base-strong-font-weight: $bold;
$base-code-color: inherit;
$base-em-color: inherit;
$base-margin-vertical: $gutter;
$base-heading-font-family: $font-recoleta;
$base-heading-font-weight: $base-body-font-weight;
$base-h1-line-height: $line-height-small;
$base-h2-font-size: $global-large-font-size;
$base-h2-line-height: $line-height-small;
$base-h3-line-height: $line-height-small;
$base-h4-line-height: $line-height-small;
$base-h5-font-size: $font-medium;
$base-h5-line-height: $line-height;
$base-h6-font-size: $font-size;
$base-h6-line-height: $line-height;
$base-list-padding-left: $gutter;
$base-selection-background: $global-color;


//
// Icon
//
$icon-button-size: $global-control-height;
$icon-button-background: transparent;
$icon-button-color: $sea;
$icon-button-hover-background: transparent;
$icon-button-hover-color: $white;
$icon-button-active-background: $icon-button-hover-background;
$icon-button-active-color: $icon-button-hover-color;
$inverse-icon-button-background: rgba($white, 0.01);
$inverse-icon-button-hover-background: transparent;
$inverse-icon-button-color: $white;
$inverse-icon-button-hover-color: $black;
$inverse-button-secondary-color: $global-color;
$inverse-button-secondary-hover-color: $global-color;
$inverse-button-secondary-active-color: $global-color;


//
// Form
//
$form-height: 56px;
$form-padding-horizontal: $gutter;
$form-padding-vertical: $gutter-small;
$form-background: $white;
$form-color: $sea;
$form-focus-background: $grey-cold;
$form-focus-color: $sea;
$form-placeholder-color: brand-colour($sea, 40%);
$form-radio-size: $global-medium-font-size;
$form-border: brand-colour($sea, 40%);
$form-focus-border: $sea;
$form-radio-checked-background: $sea;
$form-radio-checked-focus-background: $sea;
$form-radio-border: brand-colour($sea, 40%);
$form-radio-focus-border: brand-colour($sea, 40%);
$form-radio-checked-border: $sea;
$form-label-color: $sea;
$form-label-font-size: $font-small;
$internal-form-select-image: 'data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%231b365d%22%20points%3D%2212%201%207%206%2017%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%231b365d%22%20points%3D%2212%2014%207%209%2017%209%22%20%2F%3E%0A%3C%2Fsvg%3E';
$internal-form-checkbox-image: 'data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2021%2021%22%20width%3D%2221%22%20height%3D%22201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20points%3D%225%2010.75%208.5%2014.25%2016%206%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222px%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20fill%3D%22none%22%3E%3C%2Fpolyline%3E%0A%3C%2Fsvg%3E';
$inverse-form-background: $black;
$inverse-form-radio-background: transparent;


//
// Button
//
$button-line-height: $line-height;
$button-small-line-height: $line-height;
$button-large-line-height: $line-height;
$button-padding-horizontal: 35px;
$button-small-padding-horizontal: $margin;
$button-secondary-hover-background: $white;
$button-secondary-active-background: $button-secondary-hover-background;
$button-link-line-height: $line-height;
$button-link-hover-text-decoration: none;
$inverse-button-secondary-background: $white;
$inverse-button-secondary-hover-background: $inverse-button-secondary-background;
$inverse-button-secondary-active-background: $inverse-button-secondary-hover-background;


//
// Container
//
$container-xsmall-max-width: 650px;
$container-small-max-width: 894px;
$container-large-max-width: 1380px;

//
// Card
//
$card-body-padding-horizontal: 0;
$card-body-padding-vertical: $margin;
$card-body-padding-horizontal-l: 0;
$card-body-padding-vertical-l: $gutter;
$card-header-padding-horizontal: 0;
$card-header-padding-horizontal-l: 0;
$card-footer-padding-horizontal: 0;
$card-footer-padding-horizontal-l: 0;
$card-title-font-size: $global-medium-font-size;
$card-title-line-height: $line-height;
$card-default-background: transparent;
$card-default-color: inherit;
$card-default-title-color: inherit;


//
// Label
//
$label-line-height: $line-height-large;
$label-border-radius: 3px;
$label-text-transform: none;


//
// Dropdown
//
$dropdown-margin: 0;


//
// Grid
//
$grid-gutter-horizontal: $gutter-medium;
$grid-gutter-horizontal-l: $gutter-xmedium;
$grid-small-gutter-horizontal: $margin;
$grid-medium-gutter-horizontal: $gutter-medium;
$grid-large-gutter-horizontal: $gutter-large;
$grid-large-gutter-horizontal-l: $gutter-xlarge;


//
// Navbar
//
$navbar-nav-item-height: 0;
$navbar-nav-item-font-size: $global-font-size;
$navbar-nav-item-font-family: $font-recoleta;
$navbar-nav-item-hover-color: $global-color;
$navbar-nav-item-onclick-color: $global-color;
$navbar-nav-item-active-color: $global-color;
$navbar-dropdown-background: $white;
$inverse-navbar-nav-item-color: $white;
$inverse-navbar-nav-item-hover-color: $global-color;
$inverse-navbar-nav-item-onclick-color: $global-color;
$inverse-navbar-nav-item-active-color: $global-color;


//
// Dotnav
//
$dotnav-margin-horizontal: $gutter;
$dotnav-item-width: $font-xsmall;
$dotnav-item-border: $global-color;
$dotnav-item-hover-background: $global-color;
$dotnav-item-onclick-background: $global-color;
$dotnav-item-active-background: $global-color;


//
// Lightbox
//
$lightbox-background: $white;
$lightbox-item-max-width: 95vw;
$lightbox-item-max-height: 85vh;
$lightbox-toolbar-background: $white;
$lightbox-toolbar-icon-hover-color: $global-color;
$lightbox-button-size: $form-height;
$lightbox-button-background: transparent;


//
// Animation
//
$animation-duration: $speed;
$animation-fade-duration: $speed-slow;
$animation-fast-duration: $speed-fast;


//
// Text
//
$text-lead-font-size: $font-large;
$text-lead-line-height: $line-height-large;

//
// Inverse
//
$inverse-global-color: $white;
$inverse-global-border: brand-colour($sea, 20%);

//
// Countdown
//
$countdown-number-font-size-m: 5rem;
