@media print {

	*,
	::after,
	::before,
	::marker,
	.icon {
		background: 0 0 !important;
		box-shadow: none !important;
		color: $black !important;
		fill: $black !important;
		font-weight: 400 !important;
		text-align: left !important;
		text-shadow: none !important;
	}

	.uk-text-center {
		text-align: left !important;
	}

	.header,
	.footer,
	.banner,
	.instagram-section,
	.videos-section,
	.social,
	.uk-button,
	.uk-breadcrumb,
	.uk-pagination,
	.uk-modal,
	.nb-form {
		display: none !important;
	}

	.uk-section,
	.uk-width-2-3\@m {
		margin: 0 0 $global-small-margin !important;
		max-width: none !important;
		min-height: 0 !important;
		padding: 0 5px !important;
		width: 100% !important;
		text-align: left !important;
	}

	.uk-grid>* {
		padding: 0 !important;

		.uk-grid {
			padding: 0 !important;
		}
	}

	h1 {
		font-size: 52px !important;
		margin-bottom: 0 !important;
	}
}