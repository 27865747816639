.videos {

	&-section {
		overflow-x: hidden;
	}

	&-slider {

		.uk {

			&-grid-small {

				.uk-card-default {

					&.uk-card-hover {

						@include hover {
							background-color: rgba($white, .08);
						}
					}
				}
			}

			&-slidenav {
				border: $border-width solid $sea;
				border-radius: $form-height;
				height: $form-height;
				transition: background-color $transition;
				width: $form-height;
				border-color: $white;
				top: 110px;

				svg {
					left: 50%;
					position: absolute;
					transition: color $transition;
					transform: translate(-50%, -50%);
					top: 50%;
					color: $white;
				}

				&-previous {
					left: -$margin-small;
					transform: translate(-50%, -50%);

					svg {
						left: 48%;
					}
				}

				&-next {
					right: -$margin-small;
					transform: translate(50%, -50%);

					svg {
						right: 48%;
					}
				}

				@include hover {
					background-color: $white;
					border-color: $white;

					svg {
						color: $global-color;
					}
				}

				@media (min-width: $breakpoint-xlarge) {
					&-previous {
						left: -90px;
					}

					&-next {
						right: -90px;
					}
				}

				@media (max-width: $breakpoint-medium-max) {
					display: none;
				}
			}

			&-dotnav {

				>* {
					padding-bottom: $gutter-small;

					>* {
						border-color: brand-colour($global-color, 20%);

						@include hover {
							background-color: brand-colour(rgba($global-color, .5), 20%);
						}
					}
				}

				>.uk-active {

					>* {
						background-color: brand-colour($global-color, 20%);
					}
				}

				@media (max-width: $breakpoint-xsmall-max) {
					display: none;
				}
			}
		}
	}
}