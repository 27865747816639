//
// Main
//
// ========================================================================

@import 'functions';

//
// Typography
//

// Family
$font-default: 'TT Norms',
sans-serif;
$font-recoleta: 'Recoleta',
sans-serif;

// Size
$font-size: 18px;
$font-xsmall: 12px;
$font-small: 14px;
$font-medium: 20px;
$font-xmedium: 22px;
$font-large: 28px;
$font-xlarge: 67px;

// Weight
$light: 300;
$normal: 400;
$bold: 700;

// Line Height
$line-height: 1.3;
$line-height-small: 1.1;
$line-height-medium: 1.5;
$line-height-large: 1.7;


//
// Colours
//

$white: #fff;
$black: #08101b;

// Brand: Primary
$orange: #d14905;

// Brand: Secondary
$sea: #1b365d;
$pink: #b95a58;

// Custom
$primary: $orange;

// Grey
$grey-warm: #f9faf5;
$grey-cold: #f6f7f7;
$grey-inverse: #131d26;


//
// Spacings
//

$margin: 20px;
$margin-small: 10px;

$gutter: 30px;
$gutter-small: 15px;
$gutter-medium: 40px;
$gutter-large: 70px;
$gutter-xmedium: 80px;
$gutter-xlarge: 135px;


//
// Other
//

// Animation
$speed: 256ms;
$speed-fast: 128ms;
$speed-slow: ($speed * 2);
$timing: ease-in-out;
$transition: $speed $timing;

// Borders
$border-width: 2px;
$border-width-negative: -2px;
$border-width-negative-hover: -30px;
$border-width-large: 4px;
$border-radius: 6px;
$border-default: $border-width solid brand-colour($sea, 90%);


//
// UIkit Global Variables
// https://github.com/uikit/uikit/blob/master/src/scss/components/variables.scss
//
// Imports UIkit variable overrides for the theme
// These may be used by main.scss
//
// ========================================================================

@import 'uikit/variables';