.social {
	a {
		border: 2px solid $white;
		transition: $transition;
		height: 42px;
		width: 42px;

		@include hover {
			background: $white;
			color: $global-color;
		}
	}
}